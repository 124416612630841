import { useEffect, useState } from 'react';
import type { Inventory } from 'src/api/types';
import useMembership from 'src/api/useMembership';

const useDowngradeInventory = (): Inventory => {
  const { data: membership } = useMembership();
  const [value, setValue] = useState<Inventory>();

  useEffect(() => {
    const abortController = new AbortController();
    const aborted = abortController.signal.aborted;
    if (membership?.willBeDowngraded() && !aborted) {
      const v = membership.nextInventory;
      setValue(v);
    }
    return abortController.abort.bind(abortController);
  }, [membership]);

  return value;
};

export default useDowngradeInventory;
