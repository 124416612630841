import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'src/components/Translate';
import { Transaction } from 'src/components/molecule';
import isVipOrder from 'src/helpers/isVipOrder';
import isOrderForceCanceled from 'src/helpers/isOrderForceCanceled';

export const TransactionsList = ({ orders, orderMeta, orderFetchMore }) => {
  const i18n = {
    title: 'myAccount.billing.transactions.title',
    fetchMore: 'myAccount.billing.transactions.fetchMore',
    emptyDesc: 'myAccount.billing.transactions.emptyDesc.blank',
  };

  const fetchMoreOrders = () => {
    orderFetchMore({
      variables: {
        searchInput: { number: 1, limit: 10, offset: orders.length, 'filter[contain]': 'transaction' },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return Object.assign({}, previousResult, {
          ordersResult: {
            meta: fetchMoreResult.ordersResult.meta,
            data: [...previousResult.ordersResult.data, ...fetchMoreResult.ordersResult.data],
          },
        });
      },
    });
  };

  return (
    <>
      <h3 className="text-display_3">
        <Translate resourceKey={i18n.title} />
      </h3>
      {orders?.length > 0 ? (
        <div data-cy="transaction-list">
          {orders.map((order, index, array) => {
            const previousOrder = orders.length > index + 1 ? array[index + 1] : null;
            return (
              <span key={order.id}>
                {(order.state === 'CANCELLED' ||
                  (order.payment?.refundType === 'forced' && order.payment?.refunds)) && (
                  <>
                    {order.payment?.refunds?.filter(Boolean).map((refund) => (
                      <Transaction order={order} canceled={true} key={refund?.refundId} refund={refund} />
                    ))}
                  </>
                )}
                {order.state === 'CANCELLED' && isVipOrder(order) && (
                  <Transaction order={order} canceled={true} key={order.payment?.paymentCouponCode} refund={{}} />
                )}
                {!isVipOrder(order) && isOrderForceCanceled(order) && (
                  <Transaction order={order} canceled={true} key={order.updatedAt} refund={{}} />
                )}
                <Transaction order={order} previousOrder={previousOrder} canceled={false} />
              </span>
            );
          })}

          {0 < orders.length && orders.length < orderMeta.total && (
            <div className="fetch-more">
              <button className="button button_secondary" data-cy="fetch-more" onClick={fetchMoreOrders}>
                <Translate resourceKey={i18n.fetchMore} />
              </button>
            </div>
          )}
        </div>
      ) : (
        <Translate resourceKey={i18n.emptyDesc} />
      )}
    </>
  );
};

TransactionsList.propTypes = {
  orders: PropTypes.array,
  orderFetchMore: PropTypes.func,
  orderMeta: PropTypes.object,
};
