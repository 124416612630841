import React, { useEffect, useState } from 'react';
import { withPrefix } from 'gatsby';
import { useTreatments } from '@splitsoftware/splitio-react';
import { ArrowRight16, AlertSuccess24, DownloadApplication16, Launch16 } from '@hagerty/icons';
import { Button } from '@hagerty/react-components';
import { Helmet } from 'react-helmet';

import LoadingContainer from 'src/components/LoadingContainer';
import { Translate } from 'src/components/Translate';
import { Split, Treatment } from 'src/constants/Split';
import { isReady } from 'src/components/SplitContext/isReady';
import { HelpBox } from 'src/components/molecule';
import { useTracking, useTrackingContext } from 'src/components/TrackingContext';
import { Banner } from 'src/components/molecule';
import { BannerSettings } from 'src/components/molecule/Banner';
import getPdfVersion from 'src/helpers/getPdfVersion';

import './TermsConditionsSigned.module.scss';
import logTrackedEvent from 'src/helpers/logTrackedEvent';
import { trackEvent } from 'src/services/tracker';

const metadata = {
  title: 'Thank You! | HDC for State Farm',
};

type TermsConditionsSignedProps = {
  delayWorkdayLink?: boolean; // Solution to allow time for Workday account creation
  successCallback: () => void;
};

type CopyLinkProps = {
  text: string;
};

export const TermsConditionsSigned: React.FC<TermsConditionsSignedProps> = ({
  delayWorkdayLink = false,
  successCallback,
}): JSX.Element => {
  const [callOnce, setCallOnce] = useState(false);
  const pdfVersion = getPdfVersion();
  const splitIsReady = isReady();
  const treatments = useTreatments([Split.FeatureAgentOnboarding]);
  const { trackView } = useTrackingContext();
  const hasAgentOnboarding = treatments[Split.FeatureAgentOnboarding].treatment === Treatment.On;

  useTracking('terms_signed_view', null, true);

  const [banner, setBanner] = useState<BannerSettings<undefined>>();

  useEffect(() => {
    if (trackView && !callOnce) {
      trackView('terms_signed_view', null, {
        virtual_page_name: 'continue',
      });
      setCallOnce(true);
    }
  }, [trackView]);

  const handleAgentPortalClick = () => {
    logTrackedEvent(trackEvent, {
      action: 'link',
      action_name: 'Continue to HDC Agent Portal',
    });
    successCallback();
  };

  const DownloadLink: React.FC = () => {
    return (
      <a
        href={withPrefix(`/terms/agent-download/HDC-Benefits-One-Pager-${pdfVersion}.pdf`)}
        aria-label="Download"
        target="__blank"
        rel="noopener noreferrer"
        onClick={() =>
          logTrackedEvent(trackEvent, {
            action: 'link',
            action_name: 'Download PDF',
          })
        }
        data-cy="download-benefits-pdf"
      >
        <Translate resourceKey="termsAndConditions.download" />
        <DownloadApplication16 className={'icon'} />
      </a>
    );
  };

  const CopyLink: React.FC<CopyLinkProps> = ({ text }) => {
    const handleCopyToClipboard = (text) => {
      logTrackedEvent(trackEvent, {
        action: 'link',
        action_name: 'Copy link',
      });
      setBanner({
        display: true,
        message: 'termsAndConditions.copyBanner',
        color: 'unknown',
        rounded: true,
        timeout: 4000,
      });
      return navigator.clipboard.writeText(text);
    };

    return (
      <a href="#" onClick={() => handleCopyToClipboard(text)} data-cy="copy-benefits-pdf-link">
        <Translate resourceKey="termsAndConditions.copy" />
        <Launch16 className={'icon'} />
      </a>
    );
  };

  return (
    <>
      <Helmet title={metadata.title} />
      {splitIsReady ? (
        <div
          data-cy="terms-conditions-signed"
          className={hasAgentOnboarding ? 'status-info terms-conditions-signed-onboarding' : 'terms-conditions-signed'}
        >
          {!hasAgentOnboarding && (
            <div data-cy="terms-conditions-signed-thank-you">
              <AlertSuccess24 className={'icon terms-conditions-signed__icon'} />
              <Translate as="h1" className="text-display_1 header" resourceKey="termsAndConditions.greeting" />
              <div className="divider"></div>
            </div>
          )}
          {hasAgentOnboarding && (
            <div data-cy="onboarding-terms-conditions-signed-thank-you">
              <Translate as="h3" className="thank-you" resourceKey="termsAndConditions.onboardingGreeting" />
              <div className="divider"></div>
            </div>
          )}
          <div className={hasAgentOnboarding && 'onboarding-layout'}>
            <div className={hasAgentOnboarding && 'onboarding-content'}>
              <Translate
                as={hasAgentOnboarding ? 'h2' : 'h3'}
                className="lead"
                data-cy="terms-conditions-signed-setup"
                resourceKey={
                  hasAgentOnboarding
                    ? 'termsAndConditions.compensation.onboardingSetup'
                    : 'termsAndConditions.compensation.setup'
                }
              />
              <Translate
                as="p"
                className="text-lead_2"
                data-cy="terms-conditions-signed-receive"
                resourceKey={
                  hasAgentOnboarding
                    ? 'termsAndConditions.compensation.onboardingReceive'
                    : 'termsAndConditions.compensation.receive'
                }
              />
              {delayWorkdayLink ? (
                <LoadingContainer />
              ) : (
                <>
                  <div className="button-wrapper workday-button">
                    <form method="POST" action={process.env.HDC_WORKDAY_URL}>
                      <Button
                        buttonType="Primary"
                        buttonSize="Large"
                        type="submit"
                        testId="terms-conditions-signed-workday-link"
                        onClick={() =>
                          logTrackedEvent(trackEvent, {
                            _tag_name: 'hdc_sf_agent_workday_start',
                            action: 'button',
                            action_name: 'Continue',
                          })
                        }
                      >
                        <Translate
                          resourceKey={
                            hasAgentOnboarding
                              ? 'termsAndConditions.compensation.onboardingWorkday'
                              : 'termsAndConditions.workday'
                          }
                        />
                        <ArrowRight16 className={'button_primary__icon icon'} />
                      </Button>
                    </form>
                  </div>
                  <div className="button-wrapper agent-portal-button">
                    <Button
                      buttonType="Secondary"
                      testId="terms-conditions-signed-agent-portal-button"
                      onClick={handleAgentPortalClick}
                    >
                      <Translate resourceKey="termsAndConditions.agent.portal.continue" />
                    </Button>
                  </div>
                </>
              )}
            </div>
            {hasAgentOnboarding && (
              <div className="onboarding-sidebar" data-cy="onboarding-help-box">
                <HelpBox
                  header={'termsAndConditions.agent.share'}
                  content={
                    <div>
                      <div className="inset-xs" />
                      <DownloadLink />
                      <CopyLink
                        text={`${origin}${withPrefix(
                          `/terms/agent-download/HDC-Benefits-One-Pager-${pdfVersion}.pdf`
                        )}`}
                      />
                    </div>
                  }
                />
              </div>
            )}
          </div>
          {hasAgentOnboarding && banner?.display && (
            <>
              <div className="inset-l" />
              <Banner banner={banner} setBanner={setBanner} />
            </>
          )}
        </div>
      ) : (
        <LoadingContainer height={300} />
      )}
    </>
  );
};
