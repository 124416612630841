/**
 * Tracks the passed event with the passed parameters
 * prints the tracked event to the console if localStorage for it is set
 * @param {function} track the tealium track event function
 * @param {object} parameters to be tracked
 */
const logTrackedEvent = (track, parameters) => {
  track(parameters);
  const logUtagLink = localStorage.getItem('hdc:utag.link');
  if (logUtagLink === 'log') console.log(`utag.link(${JSON.stringify(parameters, null, '\t')})`);
};

export default logTrackedEvent;
