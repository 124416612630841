import React from 'react';
import { MembershipAccess } from 'src/api/useMembership'

import { ProductRadioItem } from 'src/components/molecule';
import { CardGrid } from 'src/components/designSystems/CardGrid';
import useActiveProduct from 'src/api/useActiveProduct';

type ProductRadioSelectProps = {
  membership: MembershipAccess;
  value: number;
  onChange: (value: number) => void;
};

export const ProductRadioSelect: React.FC<ProductRadioSelectProps> = ({ membership, value, onChange }) => {
  const { product } = useActiveProduct();
  return (
    <fieldset className="form-element" id="memberships-group">
      <div className="form-element__control" id="choosePlan">
        {product && (
          <CardGrid>
            {product?.mileages.map((inventory, idx) => (
              <ProductRadioItem
                key={idx}
                basePrice={product.basePrice.amount}
                inventory={inventory}
                current={membership?.hasMembership() && membership?.inventory?.skuCode === inventory?.sku}
                canceled={membership?.willBeCanceled() && membership?.inventory?.skuCode === inventory?.sku}
                renewal={membership?.hasActiveSubscription() && membership?.nextInventory?.id === inventory.id}
                onClick={() => onChange(inventory.level)}
                selected={value === inventory?.level}
              />
            ))}
          </CardGrid>
        )}
      </div>
    </fieldset>
  );
};
