import React from 'react';
import { Link } from 'gatsby';

import { Translate } from './Translate';

type BreadcrumbsProps = {
  originTransKey: string;
  originPath?: string;
  state?: object;
  dataCy?: string;
  className?: string;
  isExternal?: boolean;
  callbackFn?: () => void;
};

export default function Breadcrumbs({
  originTransKey,
  originPath,
  state = {},
  dataCy,
  className,
  isExternal = false,
  callbackFn,
}: BreadcrumbsProps) {
  const getText = () => {
    return (
      <>
        <svg className="icon-small" aria-hidden="true">
          <use xlinkHref="#12-chevron-left" />
        </svg>
        <Translate resourceKey={originTransKey} />
      </>
    );
  };

  return (
    <h5 className={`breadcrumbs ${className}`}>
      {!isExternal && originPath && (
        <Link to={originPath} state={state}>
          {getText()}
        </Link>
      )}
      {(isExternal || callbackFn) && (
        <a href={originPath || '#'} onClick={callbackFn} data-cy={dataCy}>
          {getText()}
        </a>
      )}
    </h5>
  );
}
