import React from 'react';
import { Link } from 'gatsby';
import dateFormat from 'dateformat';
import { useTreatments } from '@splitsoftware/splitio-react';

import { Profile } from 'src/api/types';
import { MembershipAccess } from 'src/api/useMembership';
import { Translate } from 'src/components/Translate';
import useDefaultPaymentMethod from 'src/api/useDefaultPaymentMethod';
import { Split, Treatment } from '../constants/Split';
import { useTrackingContext } from 'src/components/TrackingContext';

import styles from './PlanLevelWidget.module.scss';

type Props = {
  membership: MembershipAccess;
  profile: Profile;
  showDate?: boolean;
};

export default function PlanLevelWidget({ membership, profile, showDate }: Props) {
  const { defaultPaymentMethod, defaultPaymentMethodLoading } = useDefaultPaymentMethod();
  const treatments = useTreatments([Split.FeatureFreeTrial, Split.FeaturePreventDuplicateMemberships]);
  const hasFreeTrial = treatments[Split.FeatureFreeTrial].treatment === Treatment.On && membership?.level === 5;
  const hasMemberGraphTreatment =
    treatments[Split.FeaturePreventDuplicateMemberships].treatment === Treatment.MemberGraph ||
    treatments[Split.FeaturePreventDuplicateMemberships].treatment === Treatment.BothMemberGraph;
  const hdcNumber = hasMemberGraphTreatment ? membership?.data?.hdcNumber : profile?.membershipId;
  const { trackInteraction } = useTrackingContext();

  const i18n = {
    plan: 'profile.planLevelWidget.plan',
    membershipId: 'profile.planLevelWidget.membershipId',
    servicePeriod: 'manageMembership.servicePeriod.title',
    trialPeriod: 'manageMembership.trialPeriod.title',
    renewalPlan: 'profile.planLevelWidget.renewalPlan',
    annualFee: 'manageMembership.annual.fee',
    paymentMethod: 'manageMembership.payment.method',
    edit: 'myAccount.yourAccount.editInfo',
    addPayment: 'myAccount.yourAccount.addPaymentInfo',
    noPayment: 'myAccount.yourAccount.noPaymentInfo',
  };

  const cardNumber = defaultPaymentMethod?.card?.brand.toUpperCase() + ' **** ' + defaultPaymentMethod?.card?.last4;

  return (
    <>
      {profile && (
        <div className="plan-level-widget">
          <div className="widget__body">
            <div className="headings text-heading_5">
              {hdcNumber && (
                <span className="heading membership-id">
                  <Translate resourceKey={i18n.membershipId} />
                </span>
              )}
              {showDate && membership?.hasMembership() && (
                <>
                  {hasFreeTrial ? (
                    <span className="heading" data-cy="free-trial-period-title">
                      <Translate resourceKey={i18n.trialPeriod} />
                    </span>
                  ) : (
                    <span className="heading" data-cy="service-period-title">
                      <Translate resourceKey={i18n.servicePeriod} />
                    </span>
                  )}
                </>
              )}
              {membership?.isOneYearVip() && !membership?.willBeCanceled() && (
                <span className="heading" data-cy="renewal-plan-title">
                  <Translate resourceKey={i18n.renewalPlan} />
                </span>
              )}
              {(membership?.data?.price || membership?.data?.price === 0) && (
                <span className="heading" data-cy="annual-fee-heading">
                  <Translate resourceKey={i18n.annualFee} />
                </span>
              )}
              <span className="heading" data-cy="payment-method-heading">
                <Translate resourceKey={i18n.paymentMethod} />
              </span>
            </div>
            <div className="contents text-lead_4">
              <span className={styles.content} data-cy="membership-id">
                {hdcNumber}
              </span>
              {showDate && membership?.hasMembership() && (
                <span className={styles.content} data-cy="service-period-date">
                  {dateFormat(membership.effectiveAt, 'mmm d, yyyy')} - {dateFormat(membership.expireAt, 'mmm d, yyyy')}
                </span>
              )}
              {membership?.isOneYearVip() && !membership.willBeCanceled() && (
                <span className={styles.content} data-cy="renewal-plan-content">
                  {membership.nextInventory?.name}
                </span>
              )}

              <>
                {(membership?.data?.price || membership?.data?.price === 0) && (
                  <span className={styles.content} data-cy="annual-fee-content">
                    {`$${hasFreeTrial ? '70.00' : (membership?.data?.price / 100).toFixed(2)} / year`}
                  </span>
                )}
                <span className={styles.content} data-cy="payment-method-content">
                  {!defaultPaymentMethodLoading && defaultPaymentMethod ? (
                    cardNumber
                  ) : (
                    <Translate resourceKey={i18n.noPayment} />
                  )}
                  <Link
                    to={'/my-account/edit-card/'}
                    state={{ nav: true }}
                    data-cy="edit-membership"
                    className="button_secondary"
                    onClick={() => trackInteraction('link', 'edit')}
                  >
                    <Translate resourceKey={defaultPaymentMethod ? i18n.edit : i18n.addPayment} />
                  </Link>
                </span>
              </>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
