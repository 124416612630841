import React from 'react';

import { CreditCard } from 'src/components/molecule';
import { Translate } from 'src/components/Translate';
import { PaymentProvider } from 'src/constants/Payment';
import { PaymentMethod as PaymentMethodType } from 'src/api/types';

const EDIT_SUPPORTED_METHODS = [PaymentProvider.Stripe];

type Props = {
  card: PaymentMethodType['card'];
  error?: string;
  provider?: PaymentProvider;
  onEdit: () => void;
};

const PaymentMethod: React.FC<Props> = ({ card, provider, onEdit, error }) => {
  const i18n = {
    title: 'organism.paymentMethod.title',
    editPayment: 'organism.paymentMethod.editPayment',
    paymentUnsupported: 'organism.paymentMethod.unsupported',
  };
  return (
    <>
      <Translate as="h4" resourceKey={i18n.title} />
      <div className="inset-s" />
      <div className="wrapper">
        <CreditCard card={card} error={error} />
      </div>
      {EDIT_SUPPORTED_METHODS.includes(provider) ? (
        <button type="button" onClick={onEdit} className="button button_secondary" data-cy="edit-payment-method-btn">
          <Translate resourceKey={i18n.editPayment} />
        </button>
      ) : (
        <Translate resourceKey={i18n.paymentUnsupported} />
      )}
    </>
  );
};

export default PaymentMethod;
