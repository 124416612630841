import isBrowser from './isBrowser';

const getPdfVersion = () => {
  let origin;
  if (isBrowser) {
    origin = window.location.hostname === 'localhost' ? window.location.origin : process.env.HAGERTY_ROOT_URL;
  }

  let pdfVersion = 1;
  switch (origin) {
    case 'https://qa.hagerty.com':
      pdfVersion = 2;
      break;
    case 'https://mo.hagerty.com':
      pdfVersion = 3;
      break;
    case 'https://www.hagerty.com':
      pdfVersion = 4;
      break;
  }
  return pdfVersion;
};

export default getPdfVersion;
