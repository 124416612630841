import { useEffect, useState } from 'react';
import { navigate } from 'gatsby';

/**
 * This hook will redirect to the appropriate page
 *
 * Use the returned function to disable this redirect.
 */
export default function useRedirect(redirectUrl: string, targetUrl = '') {
  const [skip, setSkip] = useState(false);

  const handleRedirect = (redirectUrl, targetUrl) => {
    const external = targetUrl.match(/^https?:/);
    if (external) {
      window.location.href = targetUrl;
    } else {
      navigate(redirectUrl);
    }
  };

  useEffect(() => {
    if (skip) return;
    handleRedirect(redirectUrl, targetUrl);
  }, []);

  return setSkip;
}
